@charset "UTF-8";
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/lato/Lato-Italic.woff2") format("woff2"); }

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/lato/Lato-BoldItalic.woff2") format("woff2"); }

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/lato/Lato-Regular.woff2") format("woff2"); }

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/lato/Lato-Bold.woff2") format("woff2"); }

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/lato/Lato-Light.woff2") format("woff2"); }

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/lato/Lato-LightItalic.woff2") format("woff2"); }

@font-face {
  font-family: "cnvsicons";
  src: url("../fonts/cnvsicons/cnvsicons.eot");
  src: url("../fonts/cnvsicons/cnvsicons.eot?#iefix") format("embedded-opentype"), url("../fonts/cnvsicons/cnvsicons.woff") format("woff"), url("../fonts/cnvsicons/cnvsicons.ttf") format("truetype"), url("../fonts/cnvsicons/cnvsicons.svg#cnvsicons") format("svg");
  font-weight: normal;
  font-style: normal; }

* {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  outline: none; }

html, body {
  font-size: 16px;
  font-family: "Lato", Tahoma, Arial;
  font-weight: normal;
  color: #32325d;
  min-height: 100%;
  height: 100%;
  line-height: 1.3;
  position: relative; }
  html .intercom-namespace, body .intercom-namespace {
    display: block;
    opacity: 1; }
  html.is-pro-user .intercom-namespace, html.show-intercom .intercom-namespace, body.is-pro-user .intercom-namespace, body.show-intercom .intercom-namespace {
    display: block !important;
    opacity: 1 !important; }

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .page-container .footer-wrap {
    margin-top: auto; }

div, article, aside, footer, header, nav, section, dialog, figure, hgroup, menu {
  position: relative; }

a {
  color: #32325d;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s; }
  a:hover {
    color: #3568eb; }
  a:active {
    color: #174acd;
    transition: none; }
  a.link {
    color: #3568eb; }
    a.link:hover {
      color: #2154d7; }
    a.link:active {
      color: #174acd;
      transition: none; }
  a.link-white {
    color: #fff; }
    a.link-white:hover {
      color: rgba(255, 255, 255, 0.8); }
    a.link-white:active {
      color: rgba(255, 255, 255, 0.6);
      transition: none; }
  a.more:after {
    font-family: "cnvsicons";
    content: 'g';
    display: inline-block;
    margin-left: 6px;
    font-size: 12px;
    vertical-align: -1px; }
  a.underline {
    text-decoration: underline; }
    a.underline:hover {
      color: #3568eb; }

.c-primary {
  color: #3568eb !important; }

a.c-primary:hover {
  color: #5f87ef !important; }

.c-error {
  color: #CC4E49 !important; }

a.c-error:hover {
  color: #d6716d !important; }

.c-light {
  color: #6b7c93 !important; }

.c-white {
  color: #fff !important; }

.c-white-light {
  color: rgba(255, 255, 255, 0.8) !important; }

.fz-l {
  font-size: 18px; }

.fz-s {
  font-size: 14px; }

.fz-xs {
  font-size: 12px; }

.fw-b {
  font-weight: 600; }

p {
  line-height: 1.5;
  margin-bottom: 16px; }

b, strong {
  font-weight: 600; }

i, em {
  font-style: italic; }

h1, h2, h3, h4 {
  font-weight: normal;
  color: ctexttitle; }

h1 {
  font-size: 44px;
  line-height: 1.2;
  margin: 0 0 32px;
  font-weight: 600; }

h2 {
  font-size: 36px;
  margin: 0 0 16px;
  font-weight: 600; }

h3 {
  font-size: 28px;
  margin: 0 0 16px;
  font-weight: 600; }

h4 {
  font-size: 16px;
  margin: 0 0 10px;
  font-weight: 600; }

ul {
  list-style-position: inside; }
  ul li {
    margin-bottom: 8px; }

.w100 {
  width: 100%; }

.w50 {
  width: 50%; }

.a-left {
  text-align: left; }

.a-right {
  text-align: right; }

.a-center {
  text-align: center; }

.d-b {
  display: block; }

.d-ib {
  display: inline-block; }

.content-wrap {
  width: 92%;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box; }

.content-wrap-small {
  width: 92%;
  max-width: 914px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box; }

.m_0 {
  margin: 0px !important; }

.m_top_0 {
  margin-top: 0px !important; }

.m_left_0 {
  margin-left: 0px !important; }

.m_right_0 {
  margin-right: 0px !important; }

.m_bot_0 {
  margin-bottom: 0px !important; }

.p_0 {
  padding: 0px !important; }

.p_top_0 {
  padding-top: 0px !important; }

.p_left_0 {
  padding-left: 0px !important; }

.p_right_0 {
  padding-right: 0px !important; }

.p_bot_0 {
  padding-bottom: 0px !important; }

.m_5 {
  margin: 5px !important; }

.m_top_5 {
  margin-top: 5px !important; }

.m_left_5 {
  margin-left: 5px !important; }

.m_right_5 {
  margin-right: 5px !important; }

.m_bot_5 {
  margin-bottom: 5px !important; }

.p_5 {
  padding: 5px !important; }

.p_top_5 {
  padding-top: 5px !important; }

.p_left_5 {
  padding-left: 5px !important; }

.p_right_5 {
  padding-right: 5px !important; }

.p_bot_5 {
  padding-bottom: 5px !important; }

.m_10 {
  margin: 10px !important; }

.m_top_10 {
  margin-top: 10px !important; }

.m_left_10 {
  margin-left: 10px !important; }

.m_right_10 {
  margin-right: 10px !important; }

.m_bot_10 {
  margin-bottom: 10px !important; }

.p_10 {
  padding: 10px !important; }

.p_top_10 {
  padding-top: 10px !important; }

.p_left_10 {
  padding-left: 10px !important; }

.p_right_10 {
  padding-right: 10px !important; }

.p_bot_10 {
  padding-bottom: 10px !important; }

.m_15 {
  margin: 15px !important; }

.m_top_15 {
  margin-top: 15px !important; }

.m_left_15 {
  margin-left: 15px !important; }

.m_right_15 {
  margin-right: 15px !important; }

.m_bot_15 {
  margin-bottom: 15px !important; }

.p_15 {
  padding: 15px !important; }

.p_top_15 {
  padding-top: 15px !important; }

.p_left_15 {
  padding-left: 15px !important; }

.p_right_15 {
  padding-right: 15px !important; }

.p_bot_15 {
  padding-bottom: 15px !important; }

.m_20 {
  margin: 20px !important; }

.m_top_20 {
  margin-top: 20px !important; }

.m_left_20 {
  margin-left: 20px !important; }

.m_right_20 {
  margin-right: 20px !important; }

.m_bot_20 {
  margin-bottom: 20px !important; }

.p_20 {
  padding: 20px !important; }

.p_top_20 {
  padding-top: 20px !important; }

.p_left_20 {
  padding-left: 20px !important; }

.p_right_20 {
  padding-right: 20px !important; }

.p_bot_20 {
  padding-bottom: 20px !important; }

.m_25 {
  margin: 25px !important; }

.m_top_25 {
  margin-top: 25px !important; }

.m_left_25 {
  margin-left: 25px !important; }

.m_right_25 {
  margin-right: 25px !important; }

.m_bot_25 {
  margin-bottom: 25px !important; }

.p_25 {
  padding: 25px !important; }

.p_top_25 {
  padding-top: 25px !important; }

.p_left_25 {
  padding-left: 25px !important; }

.p_right_25 {
  padding-right: 25px !important; }

.p_bot_25 {
  padding-bottom: 25px !important; }

.m_30 {
  margin: 30px !important; }

.m_top_30 {
  margin-top: 30px !important; }

.m_left_30 {
  margin-left: 30px !important; }

.m_right_30 {
  margin-right: 30px !important; }

.m_bot_30 {
  margin-bottom: 30px !important; }

.p_30 {
  padding: 30px !important; }

.p_top_30 {
  padding-top: 30px !important; }

.p_left_30 {
  padding-left: 30px !important; }

.p_right_30 {
  padding-right: 30px !important; }

.p_bot_30 {
  padding-bottom: 30px !important; }

.m_35 {
  margin: 35px !important; }

.m_top_35 {
  margin-top: 35px !important; }

.m_left_35 {
  margin-left: 35px !important; }

.m_right_35 {
  margin-right: 35px !important; }

.m_bot_35 {
  margin-bottom: 35px !important; }

.p_35 {
  padding: 35px !important; }

.p_top_35 {
  padding-top: 35px !important; }

.p_left_35 {
  padding-left: 35px !important; }

.p_right_35 {
  padding-right: 35px !important; }

.p_bot_35 {
  padding-bottom: 35px !important; }

.m_40 {
  margin: 40px !important; }

.m_top_40 {
  margin-top: 40px !important; }

.m_left_40 {
  margin-left: 40px !important; }

.m_right_40 {
  margin-right: 40px !important; }

.m_bot_40 {
  margin-bottom: 40px !important; }

.p_40 {
  padding: 40px !important; }

.p_top_40 {
  padding-top: 40px !important; }

.p_left_40 {
  padding-left: 40px !important; }

.p_right_40 {
  padding-right: 40px !important; }

.p_bot_40 {
  padding-bottom: 40px !important; }

.m_45 {
  margin: 45px !important; }

.m_top_45 {
  margin-top: 45px !important; }

.m_left_45 {
  margin-left: 45px !important; }

.m_right_45 {
  margin-right: 45px !important; }

.m_bot_45 {
  margin-bottom: 45px !important; }

.p_45 {
  padding: 45px !important; }

.p_top_45 {
  padding-top: 45px !important; }

.p_left_45 {
  padding-left: 45px !important; }

.p_right_45 {
  padding-right: 45px !important; }

.p_bot_45 {
  padding-bottom: 45px !important; }

.m_50 {
  margin: 50px !important; }

.m_top_50 {
  margin-top: 50px !important; }

.m_left_50 {
  margin-left: 50px !important; }

.m_right_50 {
  margin-right: 50px !important; }

.m_bot_50 {
  margin-bottom: 50px !important; }

.p_50 {
  padding: 50px !important; }

.p_top_50 {
  padding-top: 50px !important; }

.p_left_50 {
  padding-left: 50px !important; }

.p_right_50 {
  padding-right: 50px !important; }

.p_bot_50 {
  padding-bottom: 50px !important; }

.m_55 {
  margin: 55px !important; }

.m_top_55 {
  margin-top: 55px !important; }

.m_left_55 {
  margin-left: 55px !important; }

.m_right_55 {
  margin-right: 55px !important; }

.m_bot_55 {
  margin-bottom: 55px !important; }

.p_55 {
  padding: 55px !important; }

.p_top_55 {
  padding-top: 55px !important; }

.p_left_55 {
  padding-left: 55px !important; }

.p_right_55 {
  padding-right: 55px !important; }

.p_bot_55 {
  padding-bottom: 55px !important; }

.m_60 {
  margin: 60px !important; }

.m_top_60 {
  margin-top: 60px !important; }

.m_left_60 {
  margin-left: 60px !important; }

.m_right_60 {
  margin-right: 60px !important; }

.m_bot_60 {
  margin-bottom: 60px !important; }

.p_60 {
  padding: 60px !important; }

.p_top_60 {
  padding-top: 60px !important; }

.p_left_60 {
  padding-left: 60px !important; }

.p_right_60 {
  padding-right: 60px !important; }

.p_bot_60 {
  padding-bottom: 60px !important; }

.m_65 {
  margin: 65px !important; }

.m_top_65 {
  margin-top: 65px !important; }

.m_left_65 {
  margin-left: 65px !important; }

.m_right_65 {
  margin-right: 65px !important; }

.m_bot_65 {
  margin-bottom: 65px !important; }

.p_65 {
  padding: 65px !important; }

.p_top_65 {
  padding-top: 65px !important; }

.p_left_65 {
  padding-left: 65px !important; }

.p_right_65 {
  padding-right: 65px !important; }

.p_bot_65 {
  padding-bottom: 65px !important; }

.m_70 {
  margin: 70px !important; }

.m_top_70 {
  margin-top: 70px !important; }

.m_left_70 {
  margin-left: 70px !important; }

.m_right_70 {
  margin-right: 70px !important; }

.m_bot_70 {
  margin-bottom: 70px !important; }

.p_70 {
  padding: 70px !important; }

.p_top_70 {
  padding-top: 70px !important; }

.p_left_70 {
  padding-left: 70px !important; }

.p_right_70 {
  padding-right: 70px !important; }

.p_bot_70 {
  padding-bottom: 70px !important; }

.m_75 {
  margin: 75px !important; }

.m_top_75 {
  margin-top: 75px !important; }

.m_left_75 {
  margin-left: 75px !important; }

.m_right_75 {
  margin-right: 75px !important; }

.m_bot_75 {
  margin-bottom: 75px !important; }

.p_75 {
  padding: 75px !important; }

.p_top_75 {
  padding-top: 75px !important; }

.p_left_75 {
  padding-left: 75px !important; }

.p_right_75 {
  padding-right: 75px !important; }

.p_bot_75 {
  padding-bottom: 75px !important; }

.m_80 {
  margin: 80px !important; }

.m_top_80 {
  margin-top: 80px !important; }

.m_left_80 {
  margin-left: 80px !important; }

.m_right_80 {
  margin-right: 80px !important; }

.m_bot_80 {
  margin-bottom: 80px !important; }

.p_80 {
  padding: 80px !important; }

.p_top_80 {
  padding-top: 80px !important; }

.p_left_80 {
  padding-left: 80px !important; }

.p_right_80 {
  padding-right: 80px !important; }

.p_bot_80 {
  padding-bottom: 80px !important; }

.m_85 {
  margin: 85px !important; }

.m_top_85 {
  margin-top: 85px !important; }

.m_left_85 {
  margin-left: 85px !important; }

.m_right_85 {
  margin-right: 85px !important; }

.m_bot_85 {
  margin-bottom: 85px !important; }

.p_85 {
  padding: 85px !important; }

.p_top_85 {
  padding-top: 85px !important; }

.p_left_85 {
  padding-left: 85px !important; }

.p_right_85 {
  padding-right: 85px !important; }

.p_bot_85 {
  padding-bottom: 85px !important; }

.m_90 {
  margin: 90px !important; }

.m_top_90 {
  margin-top: 90px !important; }

.m_left_90 {
  margin-left: 90px !important; }

.m_right_90 {
  margin-right: 90px !important; }

.m_bot_90 {
  margin-bottom: 90px !important; }

.p_90 {
  padding: 90px !important; }

.p_top_90 {
  padding-top: 90px !important; }

.p_left_90 {
  padding-left: 90px !important; }

.p_right_90 {
  padding-right: 90px !important; }

.p_bot_90 {
  padding-bottom: 90px !important; }

.m_95 {
  margin: 95px !important; }

.m_top_95 {
  margin-top: 95px !important; }

.m_left_95 {
  margin-left: 95px !important; }

.m_right_95 {
  margin-right: 95px !important; }

.m_bot_95 {
  margin-bottom: 95px !important; }

.p_95 {
  padding: 95px !important; }

.p_top_95 {
  padding-top: 95px !important; }

.p_left_95 {
  padding-left: 95px !important; }

.p_right_95 {
  padding-right: 95px !important; }

.p_bot_95 {
  padding-bottom: 95px !important; }

.m_100 {
  margin: 100px !important; }

.m_top_100 {
  margin-top: 100px !important; }

.m_left_100 {
  margin-left: 100px !important; }

.m_right_100 {
  margin-right: 100px !important; }

.m_bot_100 {
  margin-bottom: 100px !important; }

.p_100 {
  padding: 100px !important; }

.p_top_100 {
  padding-top: 100px !important; }

.p_left_100 {
  padding-left: 100px !important; }

.p_right_100 {
  padding-right: 100px !important; }

.p_bot_100 {
  padding-bottom: 100px !important; }

.m_105 {
  margin: 105px !important; }

.m_top_105 {
  margin-top: 105px !important; }

.m_left_105 {
  margin-left: 105px !important; }

.m_right_105 {
  margin-right: 105px !important; }

.m_bot_105 {
  margin-bottom: 105px !important; }

.p_105 {
  padding: 105px !important; }

.p_top_105 {
  padding-top: 105px !important; }

.p_left_105 {
  padding-left: 105px !important; }

.p_right_105 {
  padding-right: 105px !important; }

.p_bot_105 {
  padding-bottom: 105px !important; }

.m_110 {
  margin: 110px !important; }

.m_top_110 {
  margin-top: 110px !important; }

.m_left_110 {
  margin-left: 110px !important; }

.m_right_110 {
  margin-right: 110px !important; }

.m_bot_110 {
  margin-bottom: 110px !important; }

.p_110 {
  padding: 110px !important; }

.p_top_110 {
  padding-top: 110px !important; }

.p_left_110 {
  padding-left: 110px !important; }

.p_right_110 {
  padding-right: 110px !important; }

.p_bot_110 {
  padding-bottom: 110px !important; }

.m_115 {
  margin: 115px !important; }

.m_top_115 {
  margin-top: 115px !important; }

.m_left_115 {
  margin-left: 115px !important; }

.m_right_115 {
  margin-right: 115px !important; }

.m_bot_115 {
  margin-bottom: 115px !important; }

.p_115 {
  padding: 115px !important; }

.p_top_115 {
  padding-top: 115px !important; }

.p_left_115 {
  padding-left: 115px !important; }

.p_right_115 {
  padding-right: 115px !important; }

.p_bot_115 {
  padding-bottom: 115px !important; }

.m_120 {
  margin: 120px !important; }

.m_top_120 {
  margin-top: 120px !important; }

.m_left_120 {
  margin-left: 120px !important; }

.m_right_120 {
  margin-right: 120px !important; }

.m_bot_120 {
  margin-bottom: 120px !important; }

.p_120 {
  padding: 120px !important; }

.p_top_120 {
  padding-top: 120px !important; }

.p_left_120 {
  padding-left: 120px !important; }

.p_right_120 {
  padding-right: 120px !important; }

.p_bot_120 {
  padding-bottom: 120px !important; }

.m_125 {
  margin: 125px !important; }

.m_top_125 {
  margin-top: 125px !important; }

.m_left_125 {
  margin-left: 125px !important; }

.m_right_125 {
  margin-right: 125px !important; }

.m_bot_125 {
  margin-bottom: 125px !important; }

.p_125 {
  padding: 125px !important; }

.p_top_125 {
  padding-top: 125px !important; }

.p_left_125 {
  padding-left: 125px !important; }

.p_right_125 {
  padding-right: 125px !important; }

.p_bot_125 {
  padding-bottom: 125px !important; }

.m_130 {
  margin: 130px !important; }

.m_top_130 {
  margin-top: 130px !important; }

.m_left_130 {
  margin-left: 130px !important; }

.m_right_130 {
  margin-right: 130px !important; }

.m_bot_130 {
  margin-bottom: 130px !important; }

.p_130 {
  padding: 130px !important; }

.p_top_130 {
  padding-top: 130px !important; }

.p_left_130 {
  padding-left: 130px !important; }

.p_right_130 {
  padding-right: 130px !important; }

.p_bot_130 {
  padding-bottom: 130px !important; }

.m_135 {
  margin: 135px !important; }

.m_top_135 {
  margin-top: 135px !important; }

.m_left_135 {
  margin-left: 135px !important; }

.m_right_135 {
  margin-right: 135px !important; }

.m_bot_135 {
  margin-bottom: 135px !important; }

.p_135 {
  padding: 135px !important; }

.p_top_135 {
  padding-top: 135px !important; }

.p_left_135 {
  padding-left: 135px !important; }

.p_right_135 {
  padding-right: 135px !important; }

.p_bot_135 {
  padding-bottom: 135px !important; }

.m_140 {
  margin: 140px !important; }

.m_top_140 {
  margin-top: 140px !important; }

.m_left_140 {
  margin-left: 140px !important; }

.m_right_140 {
  margin-right: 140px !important; }

.m_bot_140 {
  margin-bottom: 140px !important; }

.p_140 {
  padding: 140px !important; }

.p_top_140 {
  padding-top: 140px !important; }

.p_left_140 {
  padding-left: 140px !important; }

.p_right_140 {
  padding-right: 140px !important; }

.p_bot_140 {
  padding-bottom: 140px !important; }

.m_145 {
  margin: 145px !important; }

.m_top_145 {
  margin-top: 145px !important; }

.m_left_145 {
  margin-left: 145px !important; }

.m_right_145 {
  margin-right: 145px !important; }

.m_bot_145 {
  margin-bottom: 145px !important; }

.p_145 {
  padding: 145px !important; }

.p_top_145 {
  padding-top: 145px !important; }

.p_left_145 {
  padding-left: 145px !important; }

.p_right_145 {
  padding-right: 145px !important; }

.p_bot_145 {
  padding-bottom: 145px !important; }

.m_150 {
  margin: 150px !important; }

.m_top_150 {
  margin-top: 150px !important; }

.m_left_150 {
  margin-left: 150px !important; }

.m_right_150 {
  margin-right: 150px !important; }

.m_bot_150 {
  margin-bottom: 150px !important; }

.p_150 {
  padding: 150px !important; }

.p_top_150 {
  padding-top: 150px !important; }

.p_left_150 {
  padding-left: 150px !important; }

.p_right_150 {
  padding-right: 150px !important; }

.p_bot_150 {
  padding-bottom: 150px !important; }

.m_155 {
  margin: 155px !important; }

.m_top_155 {
  margin-top: 155px !important; }

.m_left_155 {
  margin-left: 155px !important; }

.m_right_155 {
  margin-right: 155px !important; }

.m_bot_155 {
  margin-bottom: 155px !important; }

.p_155 {
  padding: 155px !important; }

.p_top_155 {
  padding-top: 155px !important; }

.p_left_155 {
  padding-left: 155px !important; }

.p_right_155 {
  padding-right: 155px !important; }

.p_bot_155 {
  padding-bottom: 155px !important; }

.m_160 {
  margin: 160px !important; }

.m_top_160 {
  margin-top: 160px !important; }

.m_left_160 {
  margin-left: 160px !important; }

.m_right_160 {
  margin-right: 160px !important; }

.m_bot_160 {
  margin-bottom: 160px !important; }

.p_160 {
  padding: 160px !important; }

.p_top_160 {
  padding-top: 160px !important; }

.p_left_160 {
  padding-left: 160px !important; }

.p_right_160 {
  padding-right: 160px !important; }

.p_bot_160 {
  padding-bottom: 160px !important; }

.m_165 {
  margin: 165px !important; }

.m_top_165 {
  margin-top: 165px !important; }

.m_left_165 {
  margin-left: 165px !important; }

.m_right_165 {
  margin-right: 165px !important; }

.m_bot_165 {
  margin-bottom: 165px !important; }

.p_165 {
  padding: 165px !important; }

.p_top_165 {
  padding-top: 165px !important; }

.p_left_165 {
  padding-left: 165px !important; }

.p_right_165 {
  padding-right: 165px !important; }

.p_bot_165 {
  padding-bottom: 165px !important; }

.m_170 {
  margin: 170px !important; }

.m_top_170 {
  margin-top: 170px !important; }

.m_left_170 {
  margin-left: 170px !important; }

.m_right_170 {
  margin-right: 170px !important; }

.m_bot_170 {
  margin-bottom: 170px !important; }

.p_170 {
  padding: 170px !important; }

.p_top_170 {
  padding-top: 170px !important; }

.p_left_170 {
  padding-left: 170px !important; }

.p_right_170 {
  padding-right: 170px !important; }

.p_bot_170 {
  padding-bottom: 170px !important; }

.m_175 {
  margin: 175px !important; }

.m_top_175 {
  margin-top: 175px !important; }

.m_left_175 {
  margin-left: 175px !important; }

.m_right_175 {
  margin-right: 175px !important; }

.m_bot_175 {
  margin-bottom: 175px !important; }

.p_175 {
  padding: 175px !important; }

.p_top_175 {
  padding-top: 175px !important; }

.p_left_175 {
  padding-left: 175px !important; }

.p_right_175 {
  padding-right: 175px !important; }

.p_bot_175 {
  padding-bottom: 175px !important; }

.m_180 {
  margin: 180px !important; }

.m_top_180 {
  margin-top: 180px !important; }

.m_left_180 {
  margin-left: 180px !important; }

.m_right_180 {
  margin-right: 180px !important; }

.m_bot_180 {
  margin-bottom: 180px !important; }

.p_180 {
  padding: 180px !important; }

.p_top_180 {
  padding-top: 180px !important; }

.p_left_180 {
  padding-left: 180px !important; }

.p_right_180 {
  padding-right: 180px !important; }

.p_bot_180 {
  padding-bottom: 180px !important; }

.m_185 {
  margin: 185px !important; }

.m_top_185 {
  margin-top: 185px !important; }

.m_left_185 {
  margin-left: 185px !important; }

.m_right_185 {
  margin-right: 185px !important; }

.m_bot_185 {
  margin-bottom: 185px !important; }

.p_185 {
  padding: 185px !important; }

.p_top_185 {
  padding-top: 185px !important; }

.p_left_185 {
  padding-left: 185px !important; }

.p_right_185 {
  padding-right: 185px !important; }

.p_bot_185 {
  padding-bottom: 185px !important; }

.m_190 {
  margin: 190px !important; }

.m_top_190 {
  margin-top: 190px !important; }

.m_left_190 {
  margin-left: 190px !important; }

.m_right_190 {
  margin-right: 190px !important; }

.m_bot_190 {
  margin-bottom: 190px !important; }

.p_190 {
  padding: 190px !important; }

.p_top_190 {
  padding-top: 190px !important; }

.p_left_190 {
  padding-left: 190px !important; }

.p_right_190 {
  padding-right: 190px !important; }

.p_bot_190 {
  padding-bottom: 190px !important; }

.m_195 {
  margin: 195px !important; }

.m_top_195 {
  margin-top: 195px !important; }

.m_left_195 {
  margin-left: 195px !important; }

.m_right_195 {
  margin-right: 195px !important; }

.m_bot_195 {
  margin-bottom: 195px !important; }

.p_195 {
  padding: 195px !important; }

.p_top_195 {
  padding-top: 195px !important; }

.p_left_195 {
  padding-left: 195px !important; }

.p_right_195 {
  padding-right: 195px !important; }

.p_bot_195 {
  padding-bottom: 195px !important; }

.m_null {
  margin: 0; }

.ml_auto {
  margin-left: auto !important; }

.mr_auto {
  margin-right: auto !important; }

.m_auto {
  margin: 0 auto !important; }

table {
  width: 100%; }
  table th, table td {
    padding: 8px 0; }
  table th {
    font-size: 14px;
    color: #6b7c93;
    font-weight: normal; }

.loading_preview {
  background: url("../images/loading-preview.svg") no-repeat center;
  float: left;
  width: 31%;
  height: 236px;
  margin: 0 3.5% 40px 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 0 2px rgba(53, 104, 235, 0), 0 2px 25px 4px rgba(50, 50, 93, 0.05), 0 1px 4px 0 rgba(50, 50, 93, 0.15);
  box-shadow: 0 0 0 2px rgba(53, 104, 235, 0), 0 2px 25px 4px rgba(50, 50, 93, 0.05), 0 1px 4px 0 rgba(50, 50, 93, 0.15);
  -webkit-transition: box-shadow 0.25s;
  -moz-transition: box-shadow 0.25s;
  -o-transition: box-shadow 0.25s;
  -ms-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s; }

.linear_progress {
  background-color: rgba(255, 255, 255, 0) !important;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 99999 !important;
  width: 100%; }

.print-h2 {
  text-align: center;
  margin: 20px; }

.print-canvas {
  border: 1px solid rgba(107, 124, 147, 0.2); }
  .print-canvas .canvas-grid {
    width: 100% !important; }
  .print-canvas.default-canvas .canvas-cell-fields .cell-placeholder-wrap {
    font-size: 50px !important; }

.print-canvas div[class*="canvas-cell-p"] {
  min-height: 230px !important; }

.print-canvas div[class*="canvas-cell-p"]:hover {
  cursor: pointer; }

.print-canvas .canvas-cell-title {
  font-size: 14px !important;
  font-weight: 400; }

.beamer_icon.active {
  right: -20px !important; }

.animated-block {
  display: inline-block; }

.label_pro {
  display: inline-block;
  vertical-align: 1px;
  margin-left: 12px;
  height: 22px;
  padding: 0 12px;
  line-height: 23px;
  font-size: 12px;
  font-weight: 900;
  color: #32325d;
  border-radius: 20px;
  background: #F8D761; }
  .label_pro:after {
    content: 'PRO'; }

.loading-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center center;
  display: block;
  margin: 0 auto 20px; }

.head-wrap {
  height: 760px;
  margin-bottom: -600px;
  background-color: #3568eb;
  background: url(../images/head-promo-line.svg) no-repeat top right, linear-gradient(280deg, rgba(235, 92, 212, 0.6) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 70%), #3568eb;
  background-blend-mode: normal, overlay, normal;
  border-radius: 0 0 0 1300px/170px; }
  .head-wrap header {
    height: 132px; }
  .head-wrap .header-logo {
    background-image: url(../images/logo-white.svg);
    margin-left: 30px; }
  .head-wrap .header-menu a {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px; }
    .head-wrap .header-menu a.active {
      color: #fff; }
    .head-wrap .header-menu a:hover {
      color: #fff; }
  .head-wrap .header-profile {
    margin-right: 30px; }
    .head-wrap .header-profile .profile-login-link {
      color: #fff; }
  .head-wrap .drop-target {
    color: #fff; }
    .head-wrap .drop-target:hover {
      color: rgba(255, 255, 255, 0.8); }
      .head-wrap .drop-target:hover:after {
        color: rgba(255, 255, 255, 0.4); }
    .head-wrap .drop-target:after {
      color: rgba(255, 255, 255, 0.4); }

.home-start {
  height: 600px;
  color: #fff;
  text-align: center;
  background: url(../images/home-head-img.png) no-repeat center 301px;
  background-size: 1020px auto;
  border-radius: 0 0 0 1300px/170px; }
  .home-start p {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 40px; }

.use-brands {
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .use-brands img {
    opacity: .6; }

.join-free {
  padding: 180px 0;
  text-align: center; }
  .join-free.content-wrap {
    width: 766px; }
  .join-free h1 {
    margin-bottom: 60px; }

.button {
  height: 52px;
  line-height: 51px;
  padding: 0 32px;
  border: none;
  border-radius: 30px;
  background: #3568eb;
  font-size: 20px;
  font-family: "Lato", Tahoma, Arial;
  -webkit-appearance: none;
  color: #fff;
  text-align: center;
  transition: 0.25s background-color, 0.25s color;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .button:hover {
    background-color: #2b5ee1;
    color: #fff; }
  .button:active {
    background-color: #2154d7;
    transform: scale(0.98); }
  .button.small {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    padding: 0 16px;
    font-weight: normal; }
  .button.medium {
    height: 44px;
    line-height: 42px;
    font-size: 18px;
    padding: 0 24px; }
  .button.large {
    height: 60px;
    line-height: 60px;
    font-size: 26px;
    padding: 0 48px;
    font-weight: 600; }
  .button[class*="icon-"]:before {
    margin-right: 8px;
    vertical-align: middle; }
  .button.ghost {
    background: none;
    color: #3568eb;
    font-size: 20px;
    font-weight: 600; }
    .button.ghost:hover {
      color: #2154d7; }
  .button.white {
    background: rgba(255, 255, 255, 0.6);
    color: #6b7c93;
    font-weight: 600; }
    .button.white:hover {
      background: rgba(255, 255, 255, 0.9); }
  .button.white-promo {
    background: #fff;
    color: #3568eb;
    font-weight: 600; }
    .button.white-promo:hover {
      background: rgba(255, 255, 255, 0.9); }
  .button.light {
    background: #f5f9fc;
    color: #3568eb;
    font-weight: 600; }
    .button.light:hover {
      background: #DBEEFE; }
  .button.wide {
    padding-left: 48px;
    padding-right: 48px; }
  .button.disabled, .button[disabled] {
    background: #EBF2F7;
    color: #8896a9;
    cursor: default;
    pointer-events: none; }
    .button.disabled:active, .button[disabled]:active {
      transform: none; }

.buttons-wrap {
  padding-top: 32px; }

input, .form-drop {
  display: block; }
  input[type='text'], input[type='password'], input[type='number'], input[type='tel'], input[type='email'],
  input .drop-target, .form-drop[type='text'], .form-drop[type='password'], .form-drop[type='number'], .form-drop[type='tel'], .form-drop[type='email'],
  .form-drop .drop-target {
    display: block;
    box-sizing: border-box;
    height: 44px;
    padding: 0 8px;
    font-family: "Lato", Tahoma, Arial;
    font-size: 18px;
    font-weight: normal;
    color: #32325d;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: border-color 0.2s, color 0.2s, background 0.2s, box-shadow 0.2s; }
    input[type='text']::-webkit-input-placeholder, input[type='password']::-webkit-input-placeholder, input[type='number']::-webkit-input-placeholder, input[type='tel']::-webkit-input-placeholder, input[type='email']::-webkit-input-placeholder,
    input .drop-target::-webkit-input-placeholder, .form-drop[type='text']::-webkit-input-placeholder, .form-drop[type='password']::-webkit-input-placeholder, .form-drop[type='number']::-webkit-input-placeholder, .form-drop[type='tel']::-webkit-input-placeholder, .form-drop[type='email']::-webkit-input-placeholder,
    .form-drop .drop-target::-webkit-input-placeholder {
      color: rgba(50, 50, 93, 0.3); }
    input[type='text']::-moz-placeholder, input[type='password']::-moz-placeholder, input[type='number']::-moz-placeholder, input[type='tel']::-moz-placeholder, input[type='email']::-moz-placeholder,
    input .drop-target::-moz-placeholder, .form-drop[type='text']::-moz-placeholder, .form-drop[type='password']::-moz-placeholder, .form-drop[type='number']::-moz-placeholder, .form-drop[type='tel']::-moz-placeholder, .form-drop[type='email']::-moz-placeholder,
    .form-drop .drop-target::-moz-placeholder {
      color: rgba(50, 50, 93, 0.3); }
    input[type='text']:-ms-input-placeholder, input[type='password']:-ms-input-placeholder, input[type='number']:-ms-input-placeholder, input[type='tel']:-ms-input-placeholder, input[type='email']:-ms-input-placeholder,
    input .drop-target:-ms-input-placeholder, .form-drop[type='text']:-ms-input-placeholder, .form-drop[type='password']:-ms-input-placeholder, .form-drop[type='number']:-ms-input-placeholder, .form-drop[type='tel']:-ms-input-placeholder, .form-drop[type='email']:-ms-input-placeholder,
    .form-drop .drop-target:-ms-input-placeholder {
      color: rgba(50, 50, 93, 0.3); }
    input[type='text']:-moz-placeholder, input[type='password']:-moz-placeholder, input[type='number']:-moz-placeholder, input[type='tel']:-moz-placeholder, input[type='email']:-moz-placeholder,
    input .drop-target:-moz-placeholder, .form-drop[type='text']:-moz-placeholder, .form-drop[type='password']:-moz-placeholder, .form-drop[type='number']:-moz-placeholder, .form-drop[type='tel']:-moz-placeholder, .form-drop[type='email']:-moz-placeholder,
    .form-drop .drop-target:-moz-placeholder {
      color: rgba(50, 50, 93, 0.3); }
    input[type='text']:focus, input[type='password']:focus, input[type='number']:focus, input[type='tel']:focus, input[type='email']:focus,
    input .drop-target:focus, .form-drop[type='text']:focus, .form-drop[type='password']:focus, .form-drop[type='number']:focus, .form-drop[type='tel']:focus, .form-drop[type='email']:focus,
    .form-drop .drop-target:focus {
      border-color: #3568eb; }
  input.bordered, .form-drop.bordered {
    border-color: rgba(107, 124, 147, 0.5); }

.field-group {
  padding-bottom: 6px; }

.field-input input, .field-input textarea {
  width: 100%; }

.field-input .input-with-action {
  padding-right: 44px; }

.field-input textarea {
  box-sizing: border-box;
  min-height: 90px;
  max-height: 220px;
  padding: 8px;
  font-family: "Lato", Tahoma, Arial;
  font-size: 18px;
  font-weight: normal;
  color: #32325d;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: border-color 0.2s, color 0.2s, background 0.2s, box-shadow 0.2s;
  resize: none; }
  .field-input textarea::placeholder {
    color: rgba(50, 50, 93, 0.3); }
  .field-input textarea:focus {
    border-color: #3568eb; }

.field-input_group {
  display: flex; }
  .field-input_group input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  .field-input_group input ~ .button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .field-input_group .drop-field {
    flex: 0 0 auto;
    border-left: 1px solid #f9f9f9; }

.field-input-action {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 10px;
  right: 10px;
  line-height: 24px;
  text-align: center;
  color: #6b7c93;
  font-size: 18px; }
  .field-input-action:hover {
    color: #3568eb; }

.field-group_error .field-label {
  color: #CC4E49; }

.field-group_error .field-input input {
  border-color: #CC4E49; }

.field-group_error .field-desc {
  color: #CC4E49; }

.field-label {
  margin-bottom: 4px;
  font-size: 14px;
  color: #6b7c93; }

.field-desc {
  margin-top: 2px;
  font-size: 10px;
  color: #6b7c93;
  min-height: 14px; }

label.label-check {
  display: inline-block;
  box-sizing: border-box;
  min-width: 18px;
  min-height: 18px; }

input[type='radio'],
input[type='checkbox'] {
  display: none !important; }
  input[type='radio']:focus,
  input[type='checkbox']:focus {
    background: cbghighlight; }
  input[type='radio'] + label.label-check,
  input[type='checkbox'] + label.label-check {
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    font-size: 14px;
    color: #32325d; }
    input[type='radio'] + label.label-check:hover .icon,
    input[type='checkbox'] + label.label-check:hover .icon {
      border-color: #3568eb; }
    input[type='radio'] + label.label-check .icon,
    input[type='checkbox'] + label.label-check .icon {
      position: absolute;
      top: 1px;
      left: 0;
      display: inline-block;
      box-sizing: border-box;
      width: 18px;
      height: 18px;
      vertical-align: top;
      background: #fff;
      border: 1px solid rgba(107, 124, 147, 0.5);
      border-radius: 50%;
      appearance: none; }
      input[type='radio'] + label.label-check .icon:active,
      input[type='checkbox'] + label.label-check .icon:active {
        background: rgba(0, 0, 0, 0.03); }
      input[type='radio'] + label.label-check .icon + span,
      input[type='checkbox'] + label.label-check .icon + span {
        display: inline-block; }
        input[type='radio'] + label.label-check .icon + span::first-letter,
        input[type='checkbox'] + label.label-check .icon + span::first-letter {
          text-transform: uppercase; }
  input[type='radio']:checked + label.label-check .icon,
  input[type='checkbox']:checked + label.label-check .icon {
    border-color: cl3;
    border-width: 6px;
    width: 6px;
    height: 6px; }
  input[type='radio'][readonly] + label.label-check,
  input[type='checkbox'][readonly] + label.label-check {
    cursor: default; }
    input[type='radio'][readonly] + label.label-check .icon,
    input[type='checkbox'][readonly] + label.label-check .icon {
      opacity: 0.6; }
  input[type='radio']:disabled + label.label-check .icon,
  input[type='checkbox']:disabled + label.label-check .icon {
    background: #E6E8E9;
    cursor: no-drop; }
    input[type='radio']:disabled + label.label-check .icon::after,
    input[type='checkbox']:disabled + label.label-check .icon::after {
      color: transparent; }
  input[type='radio']:disabled:checked + label.label-check .icon,
  input[type='checkbox']:disabled:checked + label.label-check .icon {
    background: #fff;
    opacity: 0.5; }

input[type='checkbox'] + label.label-check .icon {
  border-radius: 4px;
  width: 18px;
  height: 18px; }
  input[type='checkbox'] + label.label-check .icon::after {
    font-family: "cnvsicons";
    text-transform: none !important;
    font-style: normal;
    content: "\6c";
    position: absolute;
    display: inline-block;
    top: 2px;
    left: 2px;
    color: transparent;
    font-size: 12px;
    text-align: center;
    line-height: 1;
    transition: 0.1s; }

input[type='checkbox']:checked + label.label-check .icon {
  border-color: cl3;
  border-width: 1px;
  width: 18px;
  height: 18px; }
  input[type='checkbox']:checked + label.label-check .icon::after {
    color: #32325d; }

.form-buttons {
  margin-top: 24px; }

.StripeElement {
  background: white !important;
  height: 44px;
  display: flex !important;
  align-items: center;
  padding-left: 8px !important; }
  .StripeElement > * {
    width: 100%; }

.stripe-field-group {
  max-width: 320px;
  margin: 0 auto; }
  .stripe-field-group .stripe-description {
    margin-top: 16px; }

.tabs {
  text-align: center; }

.tab-menu {
  display: inline-block;
  padding: 10px 48px;
  text-align: center;
  color: #8896a9;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: 0.2s; }
  .tab-menu [class*="icon-"]:before {
    margin-right: 8px;
    vertical-align: middle; }
  .tab-menu:hover {
    color: #6b7c93; }
  .tab-menu.disabled {
    cursor: not-allowed;
    color: #a6b0be; }
    .tab-menu.disabled:hover {
      color: #a6b0be; }
    .tab-menu.disabled small {
      margin-left: 5px;
      color: #6b7c93; }

.tab-menu_active {
  border-color: #3568eb;
  color: #6b7c93; }

.drop {
  display: inline-block; }
  .drop[data-placement="right"] .drop-content {
    left: auto;
    right: 0; }
  .drop[disabled] .drop-target {
    color: #8896a9;
    cursor: default; }
    .drop[disabled] .drop-target:after {
      color: #8896a9; }
  .drop.form-drop {
    display: block; }
    .drop.form-drop .drop-target {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .drop.form-drop .drop-target:focus {
        border-color: transparent; }
    .drop.form-drop .drop-content {
      width: 100%;
      max-height: 157px;
      overflow: auto; }

.drop-content {
  display: none;
  z-index: 100;
  position: absolute;
  top: calc(100% + 5px);
  left: 0px;
  background: #fff;
  box-shadow: 0 2px 15px rgba(50, 50, 93, 0.16);
  border-radius: 3px;
  transform-origin: top; }
  .drop-content.show {
    display: block;
    animation: openDrop 0.2s ease both; }
  .drop-content.hide {
    animation: hideDrop 0.1s ease both; }

.drop-target {
  cursor: pointer; }
  .drop-target .drop-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .drop-target:after {
    font-family: "cnvsicons" !important;
    content: "\75";
    color: #6b7c93;
    vertical-align: -2px;
    margin-left: 4px; }
  .drop-target:hover {
    color: #3568eb; }
    .drop-target:hover:after {
      color: #3568eb; }

.drop-menu {
  min-width: 100px; }
  .drop-menu li {
    position: relative;
    list-style: none;
    margin: 0; }
    .drop-menu li:after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 8px;
      right: 8px;
      background: #f9f9f9; }
    .drop-menu li:last-child:after {
      display: none; }
    .drop-menu li a {
      display: block;
      padding: 12px 16px;
      white-space: nowrap; }
    .drop-menu li p {
      margin: 8px 16px 5px 16px; }

.drop-select li a {
  padding-right: 36px; }

.drop-select li.checked a {
  padding-right: 36px; }
  .drop-select li.checked a:after {
    font-family: "cnvsicons";
    content: "\6c";
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: inherit; }

.drop-field .drop-target {
  display: inline-block;
  height: 44px;
  line-height: 44px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;
  background: #fff; }

@-webkit-keyframes openDrop {
  0% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-moz-keyframes openDrop {
  0% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-ms-keyframes openDrop {
  0% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-o-keyframes openDrop {
  0% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes openDrop {
  0% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-webkit-keyframes hideDrop {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.9);
    display: none; } }

@-moz-keyframes hideDrop {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.9); } }

@-ms-keyframes hideDrop {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.9); } }

@-o-keyframes hideDrop {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.9); } }

@keyframes hideDrop {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.9); } }

.popup {
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto;
  height: 100%;
  width: 100%;
  background: rgba(107, 124, 147, 0.8);
  z-index: 100;
  animation: popupOverlayOpen 0.2s linear both;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s background; }
  .popup.hide {
    animation: popupOverlayHide 0.2s linear both; }
    .popup.hide .popup-layer {
      animation: popupHide 0.2s linear both; }
  .popup.create-canvas-popup .popup-content, .popup.move-canvas-popup .popup-content {
    background-color: #f5f9fc;
    padding-top: 42px;
    overflow-y: auto;
    min-height: 240px;
    display: flex;
    flex-direction: column; }
  .popup.create-canvas-popup .select-list, .popup.move-canvas-popup .select-list {
    order: 1; }
    .popup.create-canvas-popup .select-list .list-title, .popup.move-canvas-popup .select-list .list-title {
      font-size: 16px;
      font-weight: 500;
      color: #32325d;
      margin-bottom: 5px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .popup.create-canvas-popup .select-list .select-item, .popup.move-canvas-popup .select-list .select-item {
      padding: 10px;
      border-radius: 8px;
      margin-bottom: 5px;
      display: flex;
      align-items: flex-start;
      cursor: pointer; }
      .popup.create-canvas-popup .select-list .select-item .item-name-short, .popup.move-canvas-popup .select-list .select-item .item-name-short {
        width: 28px;
        height: 28px;
        min-width: 28px;
        border-radius: 4px;
        color: #fff;
        background-color: #3568eb;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        margin-right: 12px;
        margin-top: 4px; }
      .popup.create-canvas-popup .select-list .select-item .item-info, .popup.move-canvas-popup .select-list .select-item .item-info {
        flex-grow: 1; }
      .popup.create-canvas-popup .select-list .select-item:hover, .popup.create-canvas-popup .select-list .select-item.selected, .popup.move-canvas-popup .select-list .select-item:hover, .popup.move-canvas-popup .select-list .select-item.selected {
        background-color: #DBEEFE; }
        .popup.create-canvas-popup .select-list .select-item:hover .edit-link, .popup.create-canvas-popup .select-list .select-item:hover .delete-link, .popup.create-canvas-popup .select-list .select-item.selected .edit-link, .popup.create-canvas-popup .select-list .select-item.selected .delete-link, .popup.move-canvas-popup .select-list .select-item:hover .edit-link, .popup.move-canvas-popup .select-list .select-item:hover .delete-link, .popup.move-canvas-popup .select-list .select-item.selected .edit-link, .popup.move-canvas-popup .select-list .select-item.selected .delete-link {
          display: block; }
      .popup.create-canvas-popup .select-list .select-item .item-title, .popup.move-canvas-popup .select-list .select-item .item-title {
        font-size: 20px;
        font-weight: 500;
        color: #32325d; }
  .popup.move-canvas-popup .popup-content {
    max-height: calc(100vh - 500px);
    min-height: 200px; }
  .popup.create-canvas-popup .tabs .tab-menu {
    padding: 10px 15px;
    min-width: 100px; }
  .popup.create-canvas-popup .popup-title {
    padding-bottom: 10px; }
  .popup.create-canvas-popup .popup-content {
    max-height: calc(100vh - 220px); }
    .popup.create-canvas-popup .popup-content .popup-buttons {
      order: 0;
      padding-top: 0;
      padding-bottom: 30px; }
    .popup.create-canvas-popup .popup-content .select-list:nth-child(2) {
      margin-top: 20px; }
    .popup.create-canvas-popup .popup-content .select-list .drop {
      margin-left: 10px; }
      .popup.create-canvas-popup .popup-content .select-list .drop .drop-content {
        max-height: 157px;
        overflow: auto; }
    .popup.create-canvas-popup .popup-content .select-list .select-item .edit-link-wrap {
      margin-left: 10px;
      align-self: stretch;
      display: flex;
      align-items: center;
      flex-shrink: 0; }
    .popup.create-canvas-popup .popup-content .select-list .select-item .edit-link, .popup.create-canvas-popup .popup-content .select-list .select-item .delete-link {
      display: none;
      color: #3568eb;
      text-align: right;
      font-size: 16px;
      font-weight: 600;
      margin-left: 10px; }
    .popup.create-canvas-popup .popup-content .select-list .select-item .delete-link {
      color: #CC4E49; }
    .popup.create-canvas-popup .popup-content .select-list .select-item .item-canvases-count {
      font-size: 13px;
      color: #8896a9; }
    .popup.create-canvas-popup .popup-content .select-list .select-item .item-description {
      color: #6b7c93;
      font-size: 14px; }
  .popup .popup-fixed-actions .popup-buttons {
    padding: 30px 0; }

.popup-layer {
  position: relative;
  width: 600px;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(50, 50, 93, 0.3);
  border-radius: 3px;
  box-sizing: border-box;
  z-index: 200;
  animation: popupOpen 0.4s ease both;
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  .popup-layer .close-btn {
    position: absolute;
    top: 20px;
    right: 20px; }

.popup-title {
  font-size: 40px;
  text-align: center;
  padding: 24px 32px 24px; }

.popup-content {
  padding: 8px 32px 32px; }
  .popup-content--signup, .popup-content--subscribe.corner-monster {
    background-image: url("../images/monster-1_rotate.svg");
    background-repeat: no-repeat;
    background-size: 146px auto;
    background-position: bottom right; }
  .popup-content--login, .popup-content--reset-password {
    background-image: url("../images/monster-2_rotate.svg");
    background-repeat: no-repeat;
    background-size: 146px auto;
    background-position: bottom right; }
  .popup-content--subscribe .coupon-title {
    color: #000000;
    font-size: 20px; }
  .popup-content--subscribe .success-subscribe .success-img {
    max-width: 280px;
    height: auto; }
  .popup-content--subscribe .pay-plan-wrap .price-value-header {
    height: auto;
    padding: 15px 10px 40px;
    background-image: url("../images/monster-pricing-2.svg");
    background-position: bottom -35px right;
    background-size: 150px auto; }
  .popup-content--subscribe .pay-plan-wrap .plan-details {
    width: auto; }
    .popup-content--subscribe .pay-plan-wrap .plan-details .button-wrap .button {
      width: auto; }

.popup-content_light {
  background-color: #f5f9fc;
  background-repeat: no-repeat;
  background-position: right bottom;
  padding: 32px 50px 50px;
  border-radius: 0 0 3px 3px; }

.popup-buttons {
  text-align: center;
  padding: 40px 0 0; }
  .popup-buttons .button ~ .button.ghost {
    margin-top: 10px; }

@-webkit-keyframes popupOpen {
  0% {
    opacity: 0.4;
    transform: translateY(-100px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-moz-keyframes popupOpen {
  0% {
    opacity: 0.4;
    transform: translateY(-100px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-ms-keyframes popupOpen {
  0% {
    opacity: 0.4;
    transform: translateY(-100px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-o-keyframes popupOpen {
  0% {
    opacity: 0.4;
    transform: translateY(-100px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes popupOpen {
  0% {
    opacity: 0.4;
    transform: translateY(-100px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-webkit-keyframes popupOverlayOpen {
  0% {
    background: rgba(107, 124, 147, 0); }
  100% {
    background: rgba(107, 124, 147, 0.8); } }

@-moz-keyframes popupOverlayOpen {
  0% {
    background: rgba(107, 124, 147, 0); }
  100% {
    background: rgba(107, 124, 147, 0.8); } }

@-ms-keyframes popupOverlayOpen {
  0% {
    background: rgba(107, 124, 147, 0); }
  100% {
    background: rgba(107, 124, 147, 0.8); } }

@-o-keyframes popupOverlayOpen {
  0% {
    background: rgba(107, 124, 147, 0); }
  100% {
    background: rgba(107, 124, 147, 0.8); } }

@keyframes popupOverlayOpen {
  0% {
    background: rgba(107, 124, 147, 0); }
  100% {
    background: rgba(107, 124, 147, 0.8); } }

@-webkit-keyframes popupHide {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(50px); } }

@-moz-keyframes popupHide {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(50px); } }

@-ms-keyframes popupHide {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(50px); } }

@-o-keyframes popupHide {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(50px); } }

@keyframes popupHide {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(50px); } }

@-webkit-keyframes popupOverlayHide {
  0% {
    background: rgba(107, 124, 147, 0.8); }
  100% {
    background: rgba(107, 124, 147, 0); } }

@-moz-keyframes popupOverlayHide {
  0% {
    background: rgba(107, 124, 147, 0.8); }
  100% {
    background: rgba(107, 124, 147, 0); } }

@-ms-keyframes popupOverlayHide {
  0% {
    background: rgba(107, 124, 147, 0.8); }
  100% {
    background: rgba(107, 124, 147, 0); } }

@-o-keyframes popupOverlayHide {
  0% {
    background: rgba(107, 124, 147, 0.8); }
  100% {
    background: rgba(107, 124, 147, 0); } }

@keyframes popupOverlayHide {
  0% {
    background: rgba(107, 124, 147, 0.8); }
  100% {
    background: rgba(107, 124, 147, 0); } }

.notifications {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200; }

.notify {
  width: 400px;
  padding: 8px 20px;
  box-sizing: border-box;
  border-radius: 0 0 3px 3px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background: #32325d;
  box-shadow: 0 1px 2px rgba(50, 50, 93, 0.16);
  animation: openNotify 0.3s ease both; }
  .notify.hide {
    animation: hideNotify 0.3s ease both; }

.notify_success {
  background: #3568eb; }

.notify_error {
  background: #CC4E49; }

@-webkit-keyframes openNotify {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-moz-keyframes openNotify {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-ms-keyframes openNotify {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-o-keyframes openNotify {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes openNotify {
  0% {
    opacity: 0;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@-webkit-keyframes hideNotify {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(-100%); } }

@-moz-keyframes hideNotify {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(-100%); } }

@-ms-keyframes hideNotify {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(-100%); } }

@-o-keyframes hideNotify {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(-100%); } }

@keyframes hideNotify {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(-100%); } }

.snackbars {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200; }

.snackbar {
  width: 100%;
  padding: 16px 20px;
  box-sizing: border-box;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background: #3568eb;
  animation: openSnackbat .3s ease both; }
  .snackbar.hide {
    animation: hideSnackbar .3s ease both; }
  .snackbar a {
    color: inherit; }
    .snackbar a:hover {
      color: inherit;
      opacity: .9; }

.snackbar-close {
  margin-left: 20px;
  font-size: 12px; }

@keyframes openSnackbat {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes hideSnackbar {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(100%); } }

[data-icon]:before {
  font-family: "cnvsicons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "cnvsicons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-back:before {
  content: "\61"; }

.icon-copy:before {
  content: "\62"; }

.icon-print:before {
  content: "\63"; }

.icon-settings:before {
  content: "\64"; }

.icon-share:before {
  content: "\65"; }

.icon-menu:before {
  content: "\66"; }

.icon-arrow-right:before {
  content: "\67"; }

.icon-shared:before {
  content: "\68"; }

.icon-eye:before {
  content: "\69"; }

.icon-mail:before {
  content: "\6a"; }

.icon-url:before {
  content: "\6b"; }

.icon-check:before {
  content: "\6c"; }

.icon-tw:before {
  content: "\6d"; }

.icon-help:before {
  content: "\74"; }

.icon-drag:before {
  content: "\73"; }

.icon-close:before {
  content: "\72"; }

.icon-eye-close:before {
  content: "\71"; }

.icon-in:before {
  content: "\70"; }

.icon-gp:before {
  content: "\6f"; }

.icon-fb:before {
  content: "\6e"; }

.icon-basic-canvas:before {
  content: "\76"; }

.icon-custom-canvas:before {
  content: "\77"; }

.icon-play:before {
  content: "\78"; }

.icon-pause:before {
  content: "\79"; }

.icon-arrow-down:before {
  content: "\75"; }

.icon-edit:before {
  content: "\7a"; }

.icon-edit-template:before {
  content: "\41"; }

.icon-list:before {
  content: "\43"; }

.icon-grid:before {
  content: "\42"; }

.icon-delete:before {
  content: "\44"; }

.icon-search:before {
  content: "\45"; }

header {
  width: 100%;
  height: 82px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.header-logo {
  width: 82px;
  height: 22px;
  background: url(../images/logo.svg) no-repeat 0 0;
  background-size: cover;
  margin: 0 0 0 15px;
  text-indent: -10000em; }

.header-menu {
  margin: 0 0 0 40px;
  flex-grow: 1; }
  .header-menu a {
    font-size: fz;
    color: #32325d;
    display: inline-block;
    margin-right: 40px; }
    .header-menu a:first-child {
      font-weight: 600; }
    .header-menu a.active {
      font-weight: 600; }

.header-lang {
  margin-right: 42px; }

.header-profile {
  margin-right: 15px; }

footer {
  background: #32325d url(../images/monster-2.svg) no-repeat center bottom;
  background-size: auto 83px;
  color: #fff;
  line-height: 1;
  font-size: 14px; }
  @media screen and (max-width: 360px) {
    footer {
      font-size: 12px; } }
  footer .content-wrap {
    display: flex;
    justify-content: space-between;
    padding: 36px 0 104px; }

.footer-menu {
  flex-basis: 40%;
  line-height: 0; }
  @media screen and (max-width: 980px) {
    .footer-menu {
      display: none; } }
  .footer-menu a {
    color: #fff;
    display: inline-block;
    margin-right: 32px;
    line-height: 1; }
    .footer-menu a:hover {
      opacity: 0.8; }

.footer-coded {
  flex-basis: 40%;
  text-align: right; }
  @media screen and (max-width: 980px) {
    .footer-coded {
      flex-basis: 50%; } }

.use-cnvs {
  background: #f5f9fc; }
  .use-cnvs .content-wrap {
    padding: 48px 0;
    background: url(../images/monster-1.svg) no-repeat right bottom;
    background-size: 260px auto; }
  .use-cnvs p {
    font-size: 22px;
    color: #42526E;
    margin-right: 390px; }
  .use-cnvs h1 {
    font-weight: 600;
    font-size: 46px; }

.about-canvas {
  padding: 64px 0;
  background: url(../images/canvas-screen.svg) no-repeat calc(50% - 510px) center;
  background-size: 564px auto; }
  .about-canvas .content-wrap {
    padding-left: 30%; }
  .about-canvas h2 {
    /* margin-top: 10px !important; */
    font-weight: 600;
    font-size: 36px; }
    .about-canvas h2:first-child {
      margin-top: 0; }
  .about-canvas p {
    color: #32325d;
    font-size: 20px; }

.canvases {
  padding-top: 40px;
  margin-bottom: 100px; }
  .canvases .canvases-list {
    margin-top: 44px; }
  .canvases .preview-canvases-list {
    margin-top: 44px;
    display: flex; }

.canvases-head {
  margin: 0 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .canvases-head .left-block {
    display: inline-block; }
  .canvases-head .right-block {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .canvases-head .canvas-search-field {
    position: relative;
    overflow: hidden; }
    .canvases-head .canvas-search-field .icon-search {
      color: #3568eb;
      font-size: 16px;
      left: 16px;
      top: calc(50% - 8px);
      position: absolute;
      pointer-events: none; }
    .canvases-head .canvas-search-field input {
      display: block;
      width: 100px;
      height: 33px;
      transition: width 0.15s ease-in;
      margin-right: 16px;
      border-radius: 22px;
      background-color: #f5f9fc;
      padding-left: 40px;
      cursor: pointer;
      font-size: 14px; }
      .canvases-head .canvas-search-field input + .empty-placeholder {
        position: absolute;
        top: calc(50% - 8px);
        left: 40px;
        line-height: 17px;
        white-space: nowrap;
        color: #3568eb;
        font-size: 14px;
        font-weight: bold;
        pointer-events: none; }
      .canvases-head .canvas-search-field input::placeholder {
        opacity: 0;
        color: rgba(107, 124, 147, 0.9); }
      .canvases-head .canvas-search-field input:focus, .canvases-head .canvas-search-field input:not([value=""]) {
        width: 300px;
        cursor: text;
        background-color: #fff;
        border: 1px solid #3568eb; }
        .canvases-head .canvas-search-field input:focus + .empty-placeholder, .canvases-head .canvas-search-field input:not([value=""]) + .empty-placeholder {
          display: none; }
        .canvases-head .canvas-search-field input:focus::placeholder, .canvases-head .canvas-search-field input:not([value=""])::placeholder {
          opacity: 1; }
  .canvases-head .canvases-view-toggler {
    height: 33px;
    padding: 9px 16px;
    background-color: #f5f9fc;
    border-radius: 22px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .canvases-head .canvases-view-toggler:not(:last-child) {
      margin-right: 16px; }
    .canvases-head .canvases-view-toggler a {
      height: 100%;
      display: flex;
      align-items: center; }
      .canvases-head .canvases-view-toggler a .mode-icon {
        font-size: 16px;
        height: 16px;
        color: rgba(107, 124, 147, 0.6); }
        .canvases-head .canvases-view-toggler a .mode-icon.selected {
          color: #3568eb; }
      .canvases-head .canvases-view-toggler a:hover {
        opacity: 0.8; }
      .canvases-head .canvases-view-toggler a:not(:last-child)::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: calc(100% + 8px);
        background-color: rgba(107, 124, 147, 0.2);
        margin: 0 8px; }

.workspace-name h1 {
  font-size: 30px; }

.workspace-name .drop-target {
  font-size: 30px; }
  .workspace-name .drop-target:after {
    font-size: 16px;
    vertical-align: middle;
    margin-left: 6px; }
  .workspace-name .drop-target:before {
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 22px;
    background: #6b7c93; }
  .workspace-name .drop-target:hover {
    color: #32325d; }
    .workspace-name .drop-target:hover:after {
      color: #32325d; }
    .workspace-name .drop-target:hover:before {
      background: #32325d; }

.canvases-list:after {
  content: '';
  clear: both;
  display: block; }

.canvases-list .empty-state {
  text-align: center;
  color: #6B7C93;
  padding: 30px; }
  .canvases-list .empty-state .empty-title {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 8px; }
  .canvases-list .empty-state .empty-subtitle {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0; }
  .canvases-list .empty-state .empty-icon {
    margin-top: -30px;
    max-width: 100%;
    width: 330px;
    pointer-events: none; }

.canvases-list .canvas-item {
  float: left;
  display: flex;
  align-items: flex-end;
  background-color: #e0f1e0;
  background-image: url(../images/canvas-icon.svg);
  background-repeat: no-repeat;
  border-radius: 10px;
  box-shadow: 0 0 0 2px rgba(53, 104, 235, 0), 0 2px 25px 4px rgba(50, 50, 93, 0.05), 0 1px 4px 0 rgba(50, 50, 93, 0.15);
  transition: box-shadow 0.25s;
  cursor: pointer; }
  .canvases-list .canvas-item:hover {
    box-shadow: 0 0 0 2px #3568eb, 0 2px 25px 4px rgba(50, 50, 93, 0.05), 0 1px 4px 0 rgba(50, 50, 93, 0.15); }
  .canvases-list .canvas-item.draggable-canvas {
    cursor: move;
    pointer-events: auto !important; }
  .canvases-list .canvas-item .canvas-item-panel {
    background: #f9f9f9;
    box-sizing: border-box;
    padding: 16px 20px; }

.canvases-list .canvas-item_feature {
  background-image: url(../images/canvas-icon-f.svg); }

.canvases-list .canvas-item_color0 {
  background-color: #f3f7fa; }

.canvases-list .canvas-item_color1 {
  background-color: #E0F1E0; }

.canvases-list .canvas-item_color2 {
  background-color: #DBEEFE; }

.canvases-list .canvas-item_color3 {
  background-color: #FEF4D5; }

.canvases-list .canvas-item_color4 {
  background-color: #EEEAF7; }

.canvases-list .canvas-item_color5 {
  background-color: #FAE5E5; }

.canvases-list .canvas-item_color6 {
  background-color: #D1F7FB; }

.canvases-list .canvas-item_shared:after {
  font-family: "cnvsicons";
  content: 'h';
  position: absolute;
  top: 12px;
  right: 16px;
  color: #6b7c93; }

.canvases-list .canvas-item_create {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5f9fc;
  font-size: 24px;
  color: #3568eb;
  text-align: center; }
  .canvases-list .canvas-item_create .icon {
    display: block;
    font-size: 60px;
    margin-top: -10px; }

.canvases-list.mode-grid .canvas-item {
  width: 31%;
  margin: 0 3.5% 40px 0;
  height: 236px;
  background-position: center 40%;
  background-size: 72% auto; }
  .canvases-list.mode-grid .canvas-item:nth-child(3n) {
    margin-right: 0; }
  .canvases-list.mode-grid .canvas-item .canvas-item-panel {
    width: 100%;
    height: 76px;
    border-radius: 0 0 10px 10px; }

.canvases-list.mode-list .canvas-item {
  width: 100%;
  height: 86px;
  justify-content: flex-end;
  background-position: center left 16px;
  background-size: auto 50px; }
  .canvases-list.mode-list .canvas-item:not(:last-child) {
    margin-bottom: 20px; }
  .canvases-list.mode-list .canvas-item .canvas-item-panel {
    width: calc(100% - 115px);
    height: 100%;
    border-radius: 0 10px 10px 0;
    padding: 19px 40px 24px;
    display: flex;
    flex-direction: column; }
    .canvases-list.mode-list .canvas-item .canvas-item-panel .canvas-item-type {
      margin-top: auto; }
    .canvases-list.mode-list .canvas-item .canvas-item-panel .canvas-item-actions {
      top: calc(50% - 17px);
      right: 23px; }
      .canvases-list.mode-list .canvas-item .canvas-item-panel .canvas-item-actions .icon-actions {
        position: absolute;
        top: 50%;
        right: -8px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .canvases-list.mode-list .canvas-item .canvas-item-panel .canvas-item-actions .icon-actions a {
          font-size: 16px;
          color: #6b7c93;
          padding: 8px 16px;
          position: relative; }
          .canvases-list.mode-list .canvas-item .canvas-item-panel .canvas-item-actions .icon-actions a:hover {
            opacity: 0.8; }
          .canvases-list.mode-list .canvas-item .canvas-item-panel .canvas-item-actions .icon-actions a .label_pro {
            transform: scale(0.6);
            transform-origin: top left;
            margin-left: 3px;
            position: absolute;
            top: -3px;
            left: calc(100% - 25px); }

.canvases-list.mode-list .canvas-item_create {
  flex-direction: row;
  justify-content: center; }
  .canvases-list.mode-list .canvas-item_create .icon {
    margin-top: 0;
    font-size: 40px;
    margin-right: 16px; }

.canvas-item-name {
  font-size: 20px;
  max-width: calc(100% - 32px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.canvas-item-type {
  font-size: 14px; }

.canvas-item-actions {
  position: absolute;
  right: 12px;
  top: 20px; }
  .canvas-item-actions .drop-content {
    bottom: 0;
    left: auto;
    top: auto;
    right: calc(100% + 4px); }

.canvas-item-link-actions {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px; }
  .canvas-item-link-actions:hover {
    background: #DBEEFE; }
  .canvas-item-link-actions.hidden {
    visibility: hidden; }

.canvas-page {
  padding: 20px 15px 70px;
  background-color: #f3f7fa; }

.canvas-page_color0 {
  background-color: #f3f7fa; }

.canvas-page_color1 {
  background-color: #E0F1E0; }

.canvas-page_color2 {
  background-color: #DBEEFE; }

.canvas-page_color3 {
  background-color: #FEF4D5; }

.canvas-page_color4 {
  background-color: #EEEAF7; }

.canvas-page_color5 {
  background-color: #FAE5E5; }

.canvas-page_color6 {
  background-color: #D1F7FB; }

.canvas-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; }

.canvas-page-link-back {
  flex-basis: 40px;
  flex-grow: 0;
  margin-top: 4px; }
  .canvas-page-link-back a {
    display: inline-block;
    width: 24px;
    height: 24px; }

.canvas-page-info {
  flex-basis: 100%; }

.canvas-page-name {
  margin: 0 0 4px; }
  .canvas-page-name input {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    color: #32325d;
    border: none;
    background: none;
    outline: none;
    padding: 0;
    height: auto; }
    .canvas-page-name input::-webkit-input-placeholder {
      color: rgba(50, 50, 93, 0.5); }
    .canvas-page-name input::-moz-placeholder {
      color: rgba(50, 50, 93, 0.5); }
    .canvas-page-name input:-ms-input-placeholder {
      color: rgba(50, 50, 93, 0.5); }
    .canvas-page-name input:-moz-placeholder {
      color: rgba(50, 50, 93, 0.5); }
    .canvas-page-name input:hover::-webkit-input-placeholder {
      color: rgba(50, 50, 93, 0.6); }
    .canvas-page-name input:hover::-moz-placeholder {
      color: rgba(50, 50, 93, 0.6); }
    .canvas-page-name input:hover:-ms-input-placeholder {
      color: rgba(50, 50, 93, 0.6); }
    .canvas-page-name input:hover:-moz-placeholder {
      color: rgba(50, 50, 93, 0.6); }

.canvas-page-type,
.canvas-page-url {
  font-size: 14px;
  color: #6b7c93;
  display: inline-block; }

.canvas-page-type:after {
  content: '∙';
  margin: 0 8px; }

.canvas-page-url a {
  color: #6b7c93; }
  .canvas-page-url a:hover {
    color: #3568eb; }

.canvas-page-url .copy {
  font-size: 12px;
  margin-left: 8px;
  vertical-align: -1px; }

.canvas-page-actions {
  flex-shrink: 0;
  height: 32px; }
  .canvas-page-actions .button {
    margin-left: 8px; }

.canvas-view {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2px;
  background: #bbcce3;
  border: 2px solid, #bbcce3;
  box-sizing: border-box; }

[class*="canvas-cell-p"] {
  background: #fff;
  grid-column: 1;
  grid-row: 1;
  padding: 18px 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: 0.1s background, 0.4s box-shadow; }
  [class*="canvas-cell-p"]:hover {
    background: #f5f9fc;
    cursor: pointer; }
    [class*="canvas-cell-p"]:hover .canvas-cell-fields .cell-placeholder-wrap {
      color: rgba(50, 50, 93, 0.4); }
      [class*="canvas-cell-p"]:hover .canvas-cell-fields .cell-placeholder-wrap .cell-placeholder {
        transform: scale(1.05); }
  [class*="canvas-cell-p"].active {
    background: #fff;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2);
    z-index: 10; }
  [class*="canvas-cell-p"].not-empty .canvas-cell-fields .cell-placeholder-wrap {
    display: none; }

.canvas-view_lean {
  grid-template-columns: repeat(5, calc(20% - 1.6px)); }
  .canvas-view_lean .canvas-cell-p2 .canvas-cell-wrap,
  .canvas-view_lean .canvas-cell-p5 .canvas-cell-wrap,
  .canvas-view_lean .canvas-cell-p1 .canvas-cell-wrap {
    -webkit-flex-basis: 70%;
    flex-basis: 70%;
    flex: 7 0 auto; }
    .canvas-view_lean .canvas-cell-p2 .canvas-cell-wrap ~ .canvas-cell-wrap,
    .canvas-view_lean .canvas-cell-p5 .canvas-cell-wrap ~ .canvas-cell-wrap,
    .canvas-view_lean .canvas-cell-p1 .canvas-cell-wrap ~ .canvas-cell-wrap {
      -webkit-flex-basis: 30%;
      flex-basis: 30%;
      flex: 3 0 auto; }
  .canvas-view_lean .canvas-cell-p2 {
    grid-column: 1;
    grid-row: 1 / 3; }
  .canvas-view_lean .canvas-cell-p3 {
    grid-column: 2;
    grid-row: 1; }
  .canvas-view_lean .canvas-cell-p7 {
    grid-column: 2;
    grid-row: 2; }
  .canvas-view_lean .canvas-cell-p5 {
    grid-column: 3;
    grid-row: 1 / 3; }
  .canvas-view_lean .canvas-cell-p8 {
    grid-column: 4;
    grid-row: 1; }
  .canvas-view_lean .canvas-cell-p6 {
    grid-column: 4;
    grid-row: 2; }
  .canvas-view_lean .canvas-cell-p1 {
    grid-column: 5;
    grid-row: 1 / 3; }
  .canvas-view_lean .canvas-cell-p9,
  .canvas-view_lean .canvas-cell-p4 {
    grid-column: 1;
    grid-row: 3;
    width: calc(250% + 3px); }
  .canvas-view_lean .canvas-cell-p4 {
    margin-left: calc(250% + 5px); }

.canvas-view_feature {
  grid-template-columns: 1fr 1fr 1fr; }
  .canvas-view_feature .canvas-cell-p1 {
    grid-column: 1;
    grid-row: 1; }
  .canvas-view_feature .canvas-cell-p2 {
    grid-column: 1;
    grid-row: 2 / 4; }
  .canvas-view_feature .canvas-cell-p3 {
    grid-column: 2;
    grid-row: 1 / 3; }
  .canvas-view_feature .canvas-cell-p4 {
    grid-column: 3;
    grid-row: 1; }
  .canvas-view_feature .canvas-cell-p5 {
    grid-column: 3;
    grid-row: 2; }
  .canvas-view_feature .canvas-cell-p6 {
    grid-column: 3;
    grid-row: 3; }
  .canvas-view_feature .canvas-cell-p7 {
    grid-column: 2;
    grid-row: 3; }

.canvas-cell-wrap {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding-bottom: 20px;
  min-height: 220px; }

.canvas-cell-title {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: #32325d;
  margin: 0 0 10px; }

.canvas-cell-desc {
  font-size: 12px;
  color: #6b7c93;
  margin-bottom: 10px;
  white-space: pre-wrap; }

.canvas-cell-fields {
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; }
  .canvas-cell-fields .cell-placeholder-wrap {
    position: absolute;
    top: calc(50% - 40px);
    left: 0;
    display: block;
    text-align: center;
    color: rgba(50, 50, 93, 0.2);
    font-size: inherit;
    font-weight: 600;
    line-height: 1;
    transition: 0.2s;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    overflow: hidden; }
    .canvas-cell-fields .cell-placeholder-wrap .cell-placeholder {
      display: inline-block; }

.placeholder-transparent {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 15px;
  width: 100%;
  height: 100%;
  z-index: 0;
  text-align: center;
  color: transparent;
  opacity: 0;
  font-size: 80px;
  font-weight: 600;
  box-sizing: border-box; }

.canvas-cell-field-wrap {
  margin-left: -20px;
  margin-right: -20px; }

.canvas-cell-field {
  padding: 6px 20px;
  min-height: 30px;
  box-sizing: border-box;
  background: transparent;
  color: #000;
  font-size: 14px;
  transition: 0.2s;
  white-space: pre-wrap;
  -webkit-appearance: none;
  word-break: break-word;
  border: none;
  font-family: "Lato", Tahoma, Arial;
  overflow-y: visible; }
  .canvas-cell-field:hover .canvas-cell-field-drag, .canvas-cell-field:focus .canvas-cell-field-drag {
    opacity: 1; }
  .canvas-cell-field:hover {
    background: #f5f9fc;
    cursor: text; }
  .canvas-cell-field:focus {
    cursor: text;
    background: #f5f9fc; }
    .canvas-cell-field:focus + .canvas-cell-field-hint {
      opacity: 1; }
    .canvas-cell-field:focus[contenteditable]:empty:before {
      color: #6b7c93; }
  .canvas-cell-field[contenteditable]:empty:before {
    content: attr(placeholder);
    display: block;
    color: transparent;
    transition: 0.2s; }

textarea.canvas-cell-field {
  transition: none;
  overflow-y: hidden;
  display: block;
  width: 100%;
  margin: 0;
  resize: none; }

.canvas-cell-field-drag {
  position: absolute;
  left: 4px;
  top: calc(50% - 5px);
  width: 14px;
  height: 10px;
  text-align: center;
  font-size: 10px;
  color: rgba(50, 50, 93, 0.4);
  cursor: move;
  opacity: 0;
  transition: opacity 0.2s; }
  .canvas-cell-field-drag:hover {
    color: #3568eb; }

.canvas-cell-field-hint {
  margin-top: 2px;
  color: #9daec5;
  font-size: 10px;
  opacity: 0;
  transition: 0.2s; }
  .canvas-cell-field-hint.visible {
    opacity: 1; }

.canvas-view-clear {
  padding: 12px 12px 0;
  text-align: right; }
  .canvas-view-clear a {
    text-transform: uppercase;
    font-size: 14px;
    color: #32325d; }
    .canvas-view-clear a:hover {
      color: #3568eb; }

.new-canvases-type {
  display: flex;
  justify-content: space-between;
  margin-top: 30px; }

.new-canvas-type {
  width: 32%; }
  .new-canvas-type input {
    display: none; }
    .new-canvas-type input:checked ~ label {
      background-color: #DBEEFE; }
  .new-canvas-type label {
    display: block;
    width: 100%;
    height: 140px;
    box-sizing: border-box;
    padding: 107px 0 0;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center 16px;
    background-size: calc(100% - 32px) auto;
    text-align: center;
    cursor: pointer;
    transform-origin: bottom;
    transition: 0.2s background; }
    .new-canvas-type label:hover {
      background-color: #DBEEFE; }
  .new-canvas-type.type-lean label, .new-canvas-type.type-business label {
    background-image: url(../images/canvas-icon.svg); }
  .new-canvas-type.type-feature label {
    background-image: url(../images/canvas-icon-f.svg); }

.canvas-settings-colors {
  margin-top: 12px;
  overflow: hidden;
  padding: 2px; }
  .canvas-settings-colors .clearfix {
    zoom: 1; }
  .canvas-settings-colors .clearfix:before,
  .canvas-settings-colors .clearfix:after {
    content: "";
    display: table; }
  .canvas-settings-colors .clearfix:after {
    clear: both; }

.canvas-settings-color {
  float: left;
  margin-right: 16px; }
  .canvas-settings-color input {
    display: none; }
    .canvas-settings-color input:checked ~ label {
      box-shadow: 0 0 0 2px #6b7c93; }
  .canvas-settings-color label {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(107, 124, 147, 0.2);
    cursor: pointer;
    transition: box-shadow 0.2s; }
    .canvas-settings-color label:hover {
      box-shadow: 0 0 0 1px rgba(107, 124, 147, 0.5); }
  .canvas-settings-color .canvas-color0 {
    background-color: #f3f7fa; }
  .canvas-settings-color .canvas-color1 {
    background-color: #E0F1E0; }
  .canvas-settings-color .canvas-color2 {
    background-color: #DBEEFE; }
  .canvas-settings-color .canvas-color3 {
    background-color: #FEF4D5; }
  .canvas-settings-color .canvas-color4 {
    background-color: #EEEAF7; }
  .canvas-settings-color .canvas-color5 {
    background-color: #FAE5E5; }
  .canvas-settings-color .canvas-color6 {
    background-color: #D1F7FB; }

.canvas-page_compact {
  padding: 16px; }
  .canvas-page_compact .canvas-page-header {
    margin-bottom: 8px; }
  .canvas-page_compact .canvas-page-info {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .canvas-page_compact .canvas-page-name {
    width: 100%;
    padding-right: 50px;
    box-sizing: border-box; }
    .canvas-page_compact .canvas-page-name input {
      font-size: 18px; }
  .canvas-page_compact [class*="canvas-cell-p"] {
    padding: 16px 10px; }
  .canvas-page_compact .canvas-cell-title {
    font-size: 16px; }
  .canvas-page_compact .canvas-cell-fields .cell-placeholder-wrap {
    font-size: 40px; }
  .canvas-page_compact .canvas-cell-field-wrap {
    margin-left: -10px;
    margin-right: -10px; }
  .canvas-page_compact .canvas-cell-field {
    padding-left: 10px;
    padding-right: 10px;
    cursor: default;
    font-size: 12px; }
    .canvas-page_compact .canvas-cell-field:hover, .canvas-page_compact .canvas-cell-field:focus {
      background: inherit; }
    .canvas-page_compact .canvas-cell-field .canvas-cell-field-drag {
      display: none; }

.canvas-page-copyright {
  display: block;
  height: 14px;
  background: url(../images/logo.svg) no-repeat center 0;
  background-size: auto 14px;
  margin: 10px 0 0;
  font-size: 0;
  text-indent: -10000em; }

.canvas_button_add {
  display: block;
  height: 82px;
  background-color: #f5f9fc;
  text-transform: uppercase;
  font-weight: 600;
  color: #3568eb;
  text-align: center;
  line-height: 82px;
  border-radius: 10px; }
  .canvas_button_add:hover {
    background-color: #DBEEFE; }

.draggable-row {
  z-index: 100; }

.page_default {
  padding-top: 40px;
  padding-bottom: 100px; }

.page-with-sidebar {
  display: flex;
  align-items: flex-start; }
  .page-with-sidebar h2 {
    font-weight: 600;
    font-size: 36px; }
  .page-with-sidebar p {
    color: #42526E; }
  .page-with-sidebar a {
    color: #3568eb; }

.page-sidebar {
  flex: 0 0 164px;
  border-right: 4px solid rgba(107, 124, 147, 0.5); }

.page-sidebar-menu a {
  display: block;
  margin: 0 0 12px; }
  .page-sidebar-menu a.active {
    font-weight: 600; }

.page-right {
  padding-left: 72px; }

.page-import {
  width: 732px; }

.import-desc {
  font-size: 18px;
  background: url(../images/import-img.png) no-repeat 0 bottom;
  background-size: 653px 94px;
  margin-bottom: 24px;
  padding-bottom: 120px; }

.import-img {
  margin-top: 24px; }

.import-link {
  margin-top: 8vh; }

.mobile header {
  flex-direction: column;
  height: auto; }

.mobile .header-logo {
  margin: 32px 0; }

.mobile .header-lang {
  margin: 0 0 20px; }

.mobile .footer-menu {
  display: none; }

.mobile .footer-copyright {
  width: auto; }

.page-mobile {
  background: #f5f9fc url(../images/mobile-img.png) no-repeat center 56px;
  background-size: 224px 37px;
  padding: 150px 15px 60px;
  box-sizing: border-box;
  min-height: calc(100vh - 130px - 158px); }
  .page-mobile p {
    font-size: 20px;
    color: #6b7c93;
    text-align: center;
    margin-bottom: 56px; }

.page-cookie {
  font-size: 20px;
  color: #6b7c93; }
  .page-cookie h2 {
    font-size: 30px;
    margin-top: 32px; }

.popup-content_share {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px; }

.team-users .user_email {
  display: block;
  font-size: 14px;
  color: #6b7c93; }

.team-users .owner-label {
  font-size: 14px;
  font-weight: 600;
  color: #32325d; }

.team-users .user_permission .drop-target {
  font-size: 14px;
  font-weight: 600; }
  .team-users .user_permission .drop-target:disabled {
    pointer-events: none; }

.team-users .user_permission .drop-content {
  text-align: left; }

.share-soc {
  text-align: center; }
  .share-soc a {
    display: inline-block;
    margin: 0 4px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    color: #fff;
    font-size: 22px;
    line-height: 45px;
    text-align: center;
    transition: 0.2s; }
    .share-soc a:hover {
      opacity: 0.9;
      transform: translateY(-2px); }
  .share-soc .fb {
    background-color: #3B5998; }
  .share-soc .gp {
    background-color: #DC4E41; }
  .share-soc .in {
    background-color: #0077B5;
    font-size: 19px; }
  .share-soc .tw {
    background-color: #55ACEE;
    line-height: 47px; }

.pricing-page .page-title {
  font-size: 46px;
  font-weight: 600; }

.pricing-page .page-subtitle {
  font-size: 22px;
  color: #42526E; }

.pricing-page .pricing-plans-container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 1100px; }
  .pricing-page .pricing-plans-container .pay-plan-wrap {
    width: 55.5%;
    border-radius: 3px;
    box-shadow: 0 0 0 2px rgba(53, 104, 235, 0), 0 2px 25px 4px rgba(50, 50, 93, 0.05), 0 1px 4px 0 rgba(50, 50, 93, 0.15); }
  .pricing-page .pricing-plans-container .free-plan-wrap {
    position: relative;
    z-index: 0;
    width: 48.5%;
    height: 90%;
    border: 1px solid rgba(151, 151, 151, 0.3);
    min-height: 520px;
    margin-left: -15px;
    background: #fff; }

.all-features-list {
  margin-bottom: 120px; }
  .all-features-list .feature-item {
    display: flex;
    align-items: center; }
    .all-features-list .feature-item .image-wrap {
      width: 100%;
      margin-right: 72px;
      order: 1;
      background-color: #f5f9fc;
      min-height: 240px; }
      .all-features-list .feature-item .image-wrap video {
        width: 100%;
        height: auto; }
    .all-features-list .feature-item .feature-info {
      width: 100%;
      order: 2; }
      .all-features-list .feature-item .feature-info .icon {
        margin-bottom: 8px; }
      .all-features-list .feature-item .feature-info h2 {
        font-size: 36px;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 15px; }
      .all-features-list .feature-item .feature-info p {
        font-size: 20px;
        margin-bottom: 0; }
    .all-features-list .feature-item:nth-child(even) .image-wrap {
      order: 2;
      margin-right: 0;
      margin-left: 72px; }
    .all-features-list .feature-item:nth-child(even) .feature-info {
      order: 1;
      text-align: right; }
    .all-features-list .feature-item:not(:last-child) {
      margin-bottom: 70px; }

.pay-plan-wrap {
  position: relative;
  z-index: 1;
  background-color: #FFF; }
  .pay-plan-wrap .price-value-header {
    height: 205px;
    background-image: url(../images/monster-pricing.svg);
    background-position: top right;
    background-size: 125px auto;
    background-repeat: no-repeat; }
  .pay-plan-wrap .plan-details {
    background-color: #f5f9fc; }

.old-price {
  display: inline-block;
  position: relative;
  line-height: 1; }
  .old-price:after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    background-color: #CC4E49;
    transform: rotate(-19.32deg); }

.price-value-header {
  height: 176px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .price-value-header .price-line {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    line-height: 1; }
  .price-value-header .old-price {
    font-size: 30px; }
  .price-value-header .current-price {
    font-size: 26px;
    line-height: 1;
    display: flex; }
    .price-value-header .current-price .price-value-wrap {
      font-size: 20px;
      text-align: center; }
      .price-value-header .current-price .price-value-wrap .price-value {
        font-size: 100px;
        line-height: 0.8; }
      .price-value-header .current-price .price-value-wrap .decimals {
        font-size: 32px; }
  .price-value-header .free-price {
    font-size: 50px;
    font-weight: 300; }

.plan-details {
  padding: 40px; }
  .plan-details ul {
    list-style: none; }
    .plan-details ul li {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .plan-details ul li:not(:last-child) {
        margin-bottom: 20px; }
      .plan-details ul li:before {
        content: '';
        width: 20px;
        height: 20px;
        background-image: url(../images/rounded-icon-check.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 18px; }
      .plan-details ul li.not-included:before {
        background-image: url(../images/rounded-icon-not-included.svg); }
  .plan-details .button:not(.ghost) {
    font-size: 24px;
    width: 400px;
    max-width: 100%; }
  .plan-details small {
    font-size: 12px; }

@media screen and (max-width: 991.99px) {
  .pricing-page .pricing-plans-container {
    margin: 0;
    flex-direction: column; }
    .pricing-page .pricing-plans-container .pay-plan-wrap {
      width: 100%; }
    .pricing-page .pricing-plans-container .free-plan-wrap {
      width: 90%;
      margin-left: 0;
      min-height: auto; }
    .pricing-page .pricing-plans-container .price-value-header {
      height: auto;
      padding-top: 40px; } }

.auth-form-wrap {
  width: 600px;
  max-width: 90vw;
  margin: 0 auto;
  border-radius: 3px;
  box-shadow: 0 0 0 2px rgba(53, 104, 235, 0), 0 2px 25px 4px rgba(50, 50, 93, 0.05), 0 1px 4px 0 rgba(50, 50, 93, 0.15); }

.auth-form {
  width: 100%; }
  .auth-form .auth-form-title {
    font-size: 40px;
    text-align: center;
    padding: 24px 32px 24px; }
  .auth-form .auth-form-content {
    background-color: #f5f9fc;
    background-repeat: no-repeat;
    background-position: right bottom;
    padding: 32px 50px 50px;
    border-radius: 0 0 3px 3px; }
    .auth-form .auth-form-content.auth-form--signup {
      background-image: url("../images/monster-1_rotate.svg");
      background-repeat: no-repeat;
      background-size: 146px auto;
      background-position: bottom right; }
    .auth-form .auth-form-content.auth-form--signin {
      background-image: url("../images/monster-2_rotate.svg");
      background-repeat: no-repeat;
      background-size: 146px auto;
      background-position: bottom right; }
  .auth-form .auth-form-buttons {
    text-align: center;
    padding: 40px 0 0; }

.faq.faq-dark {
  padding: 80px 0;
  background-color: #32325d;
  color: #fff;
  margin-bottom: 40px; }
  .faq.faq-dark .faq-item .link-more {
    color: rgba(255, 255, 255, 0.6); }

.faq-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.faq-item {
  flex: 0 0 47%;
  margin-bottom: 24px; }
  .faq-item h4 {
    margin-bottom: 8px; }
  .faq-item p {
    font-size: 14px;
    margin-bottom: 6px;
    line-height: 1.3; }
  .faq-item .link-more {
    font-size: 12px;
    color: #6b7c93;
    text-decoration: underline; }

.template-builder {
  background-color: #f9f9f9; }
  .template-builder .template-header {
    padding: 25px 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .template-builder .template-header .left-col {
      display: flex;
      align-items: center;
      flex-grow: 1;
      margin-right: 15px; }
    .template-builder .template-header .icon-back {
      margin-right: 15px; }
    .template-builder .template-header .button {
      font-weight: 600;
      border: #ededed 1px solid; }
  .template-builder .template-title-field {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: #32325d;
    font-family: "Lato", Tahoma, Arial;
    padding: 0;
    background-color: transparent;
    border: none;
    width: 100%; }
    .template-builder .template-title-field::placeholder {
      color: rgba(50, 50, 93, 0.5); }
    .template-builder .template-title-field:hover::placeholder {
      color: rgba(50, 50, 93, 0.6); }
  .template-builder .template-builder-wrap {
    position: relative;
    padding-left: 25px;
    padding-right: 65px;
    padding-bottom: 60px; }
  .template-builder .template-builder-container {
    max-width: 100%;
    background-color: #f9f9f9;
    overflow: auto;
    overflow-x: scroll;
    position: relative; }
  .template-builder .template-grid-lines {
    background-color: #ffffff;
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
    z-index: 1; }
    .template-builder .template-grid-lines tr td {
      border: 2px dashed #DBEEFE;
      padding: 0;
      box-sizing: border-box; }
      .template-builder .template-grid-lines tr td .add-cell-btn {
        opacity: 0;
        display: flex;
        width: 100%;
        height: 100%;
        background-color: #f5f9fc;
        align-items: center;
        justify-content: center;
        -webkit-appearance: none;
        border: none;
        transition: opacity 0.1s ease-in;
        cursor: pointer; }
        .template-builder .template-grid-lines tr td .add-cell-btn:after {
          content: '';
          opacity: 0;
          display: block;
          width: 38px;
          height: 38px;
          background-image: url("../images/plus_icon.svg");
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat; }
        .template-builder .template-grid-lines tr td .add-cell-btn.highlight {
          opacity: 1;
          background-color: #FEF4D5; }
      .template-builder .template-grid-lines tr td:hover .add-cell-btn {
        opacity: 1; }
        .template-builder .template-grid-lines tr td:hover .add-cell-btn:after {
          opacity: 1; }
    .template-builder .template-grid-lines tr.highlight td .add-cell-btn {
      background-color: #FEF4D5;
      opacity: 1; }
  .template-builder .template-cell {
    box-sizing: border-box;
    border: 1px solid rgba(107, 124, 147, 0.3);
    background-color: #ffffff;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 15px; }
    .template-builder .template-cell.cell-error {
      border-color: #CC4E49; }
    .template-builder .template-cell.react-draggable-dragging {
      background-color: #ffffff;
      z-index: 100 !important;
      box-shadow: -4px 6px 10px rgba(50, 50, 101, 0.13); }
    .template-builder .template-cell + .react-grid-placeholder {
      background-color: #DBEEFE;
      opacity: 0.5;
      z-index: 1; }
    .template-builder .template-cell > .react-resizable-handle {
      opacity: 0;
      background: #FAFAFA;
      border-top-left-radius: 100%;
      width: 32px;
      height: 32px;
      background-size: 7px 7px;
      background-position: bottom 8px right 8px;
      background-repeat: no-repeat;
      background-image: url("../images/resize_handle_blur.svg");
      transition: opacity 0.1s; }
      .template-builder .template-cell > .react-resizable-handle::after {
        content: none; }
      .template-builder .template-cell > .react-resizable-handle:hover {
        background-color: #ECF6FF;
        background-image: url("../images/resize_handle.svg"); }
    .template-builder .template-cell .draggable-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      cursor: move; }
    .template-builder .template-cell .cell-number {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: move;
      text-align: center;
      color: rgba(50, 50, 93, 0.2);
      font-size: 80px;
      font-weight: 600;
      position: relative; }
      .template-builder .template-cell .cell-number > div {
        width: 100%;
        display: flex !important; }
      .template-builder .template-cell .cell-number .cell-number-field {
        text-align: center;
        color: rgba(50, 50, 93, 0.2);
        font-size: 80px;
        font-weight: 600;
        max-width: 100%;
        height: auto;
        z-index: 1; }
    .template-builder .template-cell:hover > .react-resizable-handle {
      opacity: 1; }
    .template-builder .template-cell .cell-title-field, .template-builder .template-cell .cell-description-field, .template-builder .template-cell .cell-number-field {
      overflow-y: hidden;
      border: none;
      padding: 0;
      margin: 0;
      overflow-y: hidden;
      resize: none;
      font-family: "Lato", Tahoma, Arial; }
      .template-builder .template-cell .cell-title-field:focus::placeholder, .template-builder .template-cell .cell-description-field:focus::placeholder, .template-builder .template-cell .cell-number-field:focus::placeholder {
        opacity: 0; }
    .template-builder .template-cell .cell-title-field {
      font-size: 20px;
      line-height: 24px;
      min-height: 24px;
      font-weight: bold;
      color: #32325d;
      margin-bottom: 8px;
      padding-right: 20px;
      text-transform: uppercase; }
      .template-builder .template-cell .cell-title-field::placeholder {
        color: #32325d; }
    .template-builder .template-cell .cell-description-field {
      color: #6b7c93;
      font-size: 12px;
      line-height: 14px;
      min-height: 14px; }
      .template-builder .template-cell .cell-description-field::placeholder {
        color: #6b7c93; }
    .template-builder .template-cell .cell-menu-wrap {
      position: absolute;
      top: 12px;
      right: 10px; }
      .template-builder .template-cell .cell-menu-wrap .drop-content {
        top: 0;
        left: auto;
        bottom: auto;
        right: calc(100% + 4px); }
    .template-builder .template-cell .cell-menu-toggle {
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px; }
      .template-builder .template-cell .cell-menu-toggle:hover {
        background: #DBEEFE; }
  .template-builder .quantity-change-wrap {
    text-align: center; }
    .template-builder .quantity-change-wrap .quantity-change {
      display: inline-flex;
      align-items: center;
      justify-content: center; }
      .template-builder .quantity-change-wrap .quantity-change .title {
        color: #B7C7D5;
        text-transform: uppercase;
        margin-right: 16px;
        font-size: 14px;
        font-weight: bold; }
        .template-builder .quantity-change-wrap .quantity-change .title b {
          color: #32325D; }
      .template-builder .quantity-change-wrap .quantity-change .quantity-btn {
        width: 36px;
        height: 36px;
        border: 2px solid #DBEEFE;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 16px 16px;
        transition: none;
        -webkit-appearance: none;
        cursor: pointer; }
        .template-builder .quantity-change-wrap .quantity-change .quantity-btn:not(:last-child) {
          margin-right: 6px; }
        .template-builder .quantity-change-wrap .quantity-change .quantity-btn.plus {
          background-image: url("../images/plus_icon_blur.svg"); }
        .template-builder .quantity-change-wrap .quantity-change .quantity-btn.minus {
          background-image: url("../images/minus_icon_blur.svg"); }
        .template-builder .quantity-change-wrap .quantity-change .quantity-btn:hover {
          border-color: #3568eb; }
          .template-builder .quantity-change-wrap .quantity-change .quantity-btn:hover.plus {
            background-image: url("../images/plus_icon.svg"); }
          .template-builder .quantity-change-wrap .quantity-change .quantity-btn:hover.minus {
            background-image: url("../images/minus_icon.svg"); }
    .template-builder .quantity-change-wrap.bottom {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%; }
    .template-builder .quantity-change-wrap.right {
      position: absolute;
      right: -55px;
      top: calc(50% - 30px);
      transform: rotate(90deg); }

.canvas-grid-wrap {
  background-color: #fff;
  overflow: auto;
  max-width: 100%;
  margin: 0 20px 70px; }

.canvas-grid {
  min-width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2px;
  background: #bbcce3;
  border: 2px solid #bbcce3;
  box-sizing: border-box; }
  .canvas-grid .canvas-cell-p {
    z-index: 1; }
    .canvas-grid .canvas-cell-p.active {
      z-index: 10; }
    .canvas-grid .canvas-cell-p .canvas-cell-wrap {
      box-sizing: border-box; }
  .canvas-grid .canvas-cell-bg {
    background-color: #fff;
    pointer-events: 'none'; }
